const routes = [{
        path: "/corporate_parent_not_found",
        name: "notFound",
        component: () =>
            import ("../components/CorporateParentNotFound.vue"),
    },{
        path: "/canpay",
        name: "canpay",
        component: () =>
            import ("../components/CanpayLandingPage.vue"),
    },
    {
        path: "/",
        name: "SplashPage",
        component: () =>
            import ("../components/newIndex.vue"),
    },
    {
        path: "/:corporatename",
        name: "LandingPage",
        component: () =>
            import ("../components/LandingPage.vue"),
    },
    {
        path: "*",
        component: () =>
            import ("../components/PageNotFound.vue"),
    }
];



export default routes;