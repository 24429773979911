<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
   mounted: function () {
    let self = this;
    // Intercom integration
    self.$intercom.boot({
      user_id:
        self.consumer_details != null ? self.consumer_details.user_id : null,
      name:
        self.consumer_details != null
          ? self.consumer_details.first_name +
            " " +
            self.consumer_details.middle_name +
            " " +
            self.consumer_details.last_name
          : null,
      email: self.consumer_details != null ? self.consumer_details.email : null,
      hide_default_launcher: false,
    });
  },
};


</script>
